import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { TYPO } from "styles/Typo";
import { KOREA, US } from "utils/constant/ITME_INFO";

interface Props {
  hidden?: boolean;
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  smallVer?: boolean;
  cat?: string;
  loc?: string;
}

function MonetaryBtn({
  hidden,
  isActive,
  setIsActive,
  smallVer = false,
  cat,
  loc,
}: Props) {
  const router = useRouter();
  useEffect(() => {
    if (router.locale === "ko") {
      if (cat === "Stock" && loc === US) {
        setIsActive(false);
      } else setIsActive(true);
    }
    if (router.locale === "en") {
      setIsActive(true);
    }
  }, []);

  const basicStyle =
    "w-[60px] h-[34px] px-1 rounded-[10px] items-center inline-flex";

  if (router.locale === "ko") {
    return (
      <div
        className={`${hidden && "hidden"} relative cursor-pointer`}
        onClick={() => setIsActive(!isActive)}
      >
        {smallVer && (
          <div className="w-fit h-[26px] p-1 relative bg-gray-100 rounded-lg cursor-pointer whitespace-nowrap flex items-center">
            <div
              className={`${
                !isActive ? "text-gray70 bg-white" : "text-gray50"
              } h-full px-1 rounded-[5px] justify-center items-center flex text-center`}
            >
              <span className="text-center text-xs font-normal leading-3 ">
                외화
              </span>
            </div>
            <div
              className={` ${
                isActive ? "text-gray70 bg-white" : "text-gray50"
              } h-full px-1  rounded-[5px] justify-center items-center flex text-center`}
            >
              <span className="text-center text-xs font-normal leading-3">
                원화
              </span>
            </div>
          </div>
        )}
        {!smallVer && (
          // <div
          //   className={`  ${
          //     isActive ? "bg-blue40 justify-end" : "bg-gray10 justify-start "
          //   }   ${basicStyle}`}
          // >
          //   <div
          //     className={`
          //   px-[7px] py-[2px]   bg-white rounded-[8px] justify-start items-center gap-2.5 flex`}
          //   >
          //     <div
          //       className={`${
          //         isActive ? "text-blue40" : "text-neutral-300"
          //       } w-3 text-center  text-sm font-semibold font-['Pretendard'] leading-[22px]`}
          //     >
          //       {isActive ? "원" : "$"}
          //     </div>
          //   </div>
          // </div>
          <div
            className={`w-[60px] h-[34px] p-1 relative bg-gray10 rounded-[10px] cursor-pointer whitespace-nowrap flex items-center`}
          >
            <div
              className={`${
                !isActive ? "text-gray70 bg-white" : "text-gray30"
              } h-full w-[26px] px-1 rounded-[8px] justify-center items-center flex text-center`}
            >
              <span className={`text-center ${TYPO.TYPO7_1_SB}`}> $</span>
            </div>
            <div
              className={` ${
                isActive ? "text-gray70 bg-white" : "text-gray30"
              } h-full w-[26px]  px-1  rounded-[8px] justify-center items-center flex text-center`}
            >
              <span className={`text-center ${TYPO.TYPO7_1_SB}`}> 원</span>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${hidden && "hidden"} relative cursor-pointer`}
        onClick={() => setIsActive(!isActive)}
      >
        {smallVer && (
          <div className="w-fit h-[26px] p-1 relative bg-gray-100 rounded-lg cursor-pointer whitespace-nowrap flex items-center">
            <div
              className={`${
                !isActive ? "text-gray70 bg-white" : "text-gray50"
              } h-full px-1 rounded-[5px] justify-center items-center flex text-center`}
            >
              <span className={`text-center text-xs font-normal leading-3`}>
                currency
              </span>
            </div>
            <div
              className={` ${
                isActive ? "text-gray70 bg-white" : "text-gray50"
              } h-full px-1  rounded-[5px] justify-center items-center flex text-center`}
            >
              <span className={`text-center text-xs font-normal leading-3`}>
                $
              </span>
            </div>
          </div>
        )}
        {!smallVer && (
          //           <div
          //             className={`  ${basicStyle} ${
          //               isActive ? "bg-blue40 justify-end" : "bg-zinc-200 justify-start "
          //             }  `}
          //           >
          //             <div
          //               className={`
          // px-[7px] py-[2px] bg-white rounded-[5px] shadow justify-start items-center gap-2.5 flex`}
          //             >
          //               <div
          //                 className={`${
          //                   isActive ? "text-blue40" : "text-neutral-300"
          //                 } w-3 text-center  text-sm font-semibold font-['Pretendard']  leading-[22px]`}
          //               >
          //                 $
          //               </div>
          //             </div>
          //           </div>
          <div
            className={`w-[60px] h-[34px] p-1 relative bg-gray10 rounded-[10px] cursor-pointer whitespace-nowrap flex items-center`}
          >
            <div
              className={`${
                !isActive ? "text-gray70 bg-white" : "text-gray30"
              } h-full w-[26px] px-1 rounded-[8px] justify-center items-center flex text-center`}
            >
              <span className={`text-center ${TYPO.TYPO7_1_SB}`}>₩</span>
            </div>
            <div
              className={` ${
                isActive ? "text-gray70 bg-white" : "text-gray30"
              } h-full w-[26px]  px-1  rounded-[8px] justify-center items-center flex text-center`}
            >
              <span className={`text-center ${TYPO.TYPO7_1_SB}`}>$</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MonetaryBtn;
